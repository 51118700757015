<template>
    <div class="root" id="_contacts">
        <div class="container">
            <div class="wrapper">
                <h1>Neváhejte a pošlete nám nezávaznou poptávku</h1>
                <h2>Kontaktujte nás pomoci emailu nebo volejte na telefonní číslo. <br>Rádi si s Vámi projdeme Váš projekt a navrhneme řešení.</h2>
                <a href="tel:+420736408338">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M25.4125 26.85L28.2125 24.05C28.5897 23.6776 29.0668 23.4227 29.586 23.3161C30.1053 23.2096 30.6442 23.2562 31.1375 23.4501L34.55 24.8126C35.0486 25.0149 35.476 25.3603 35.7785 25.8052C36.0811 26.2501 36.2451 26.7746 36.25 27.3126V33.5626C36.2471 33.9285 36.1702 34.2901 36.0239 34.6256C35.8775 34.961 35.6648 35.2634 35.3986 35.5145C35.1323 35.7656 34.818 35.9602 34.4745 36.0866C34.131 36.213 33.7656 36.2686 33.4 36.2501C9.48755 34.7625 4.66255 14.5126 3.75005 6.76255C3.70769 6.38197 3.74639 5.99674 3.86361 5.63219C3.98082 5.26764 4.1739 4.93204 4.43013 4.64747C4.68636 4.3629 4.99994 4.13581 5.35024 3.98113C5.70054 3.82645 6.07962 3.7477 6.46255 3.75005H12.5C13.0388 3.75165 13.5648 3.91439 14.0103 4.21734C14.4558 4.52029 14.8005 4.9496 15 5.45005L16.3625 8.86255C16.5629 9.35389 16.614 9.89335 16.5095 10.4136C16.405 10.9338 16.1495 11.4117 15.775 11.7876L12.975 14.5876C12.975 14.5876 14.5875 25.5 25.4125 26.85Z"
                            fill="#EE306F" />
                    </svg>
                    +420 736 408 338
                </a>
                <a href="mail:info@jvideas.cz">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M28.645 31.9684L40 7.92505L0 20.6434L15.3267 23.915L15.3983 32.075L19.9967 27.7134L16.4183 24.2534L28.645 31.97V31.9684Z"
                            fill="#EE306F" />
                    </svg>
                    info@jvideas.cz
                </a>
            </div>
            <div class="wrapper">
                <svg class="animated" id="freepik_stories-mail-sent" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"
                    version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs">
                    <g id="freepik--background-simple--inject-7" style="transform-origin: 259.30806px 223.971596px;" class="animable">
                        <path
                            d="M141.5,158.12C64.6,203,60,354.53,169.38,351.63c48.91-1.3,63.2-52.59,107.34-70.59,41.44-16.91,77.17,17,119-21.4,49.29-45.2,56.37-155.75-16.89-163.14-31.9-3.22-44.2,28.63-72.07,35.68C250.26,146.48,198.64,124.79,141.5,158.12Z"
                            style="fill: rgb(89, 49, 116); transform-origin: 259.308075px 223.971596px;" id="elia1bzwu9x49"
                            class="animable"></path>
                        <g id="eljbjm1d5kne">
                            <path
                                d="M141.5,158.12C64.6,203,60,354.53,169.38,351.63c48.91-1.3,63.2-52.59,107.34-70.59,41.44-16.91,77.17,17,119-21.4,49.29-45.2,56.37-155.75-16.89-163.14-31.9-3.22-44.2,28.63-72.07,35.68C250.26,146.48,198.64,124.79,141.5,158.12Z"
                                style="fill: rgb(255, 255, 255); opacity: 0.9; transform-origin: 259.308075px 223.971596px;"
                                class="animable"></path>
                        </g>
                    </g>
                    <g id="freepik--Shadow--inject-7" style="transform-origin: 250px 416.239983px;" class="animable">
                        <ellipse cx="250" cy="416.24" rx="193.89" ry="11.32"
                            style="fill: rgb(245, 245, 245); transform-origin: 250px 416.24px;" id="elmmfa1q2ekjd" class="animable">
                        </ellipse>
                    </g>
                    <g id="freepik--Mail--inject-7" style="transform-origin: 178.139999px 174.596748px;" class="animable">
                        <polygon points="48.86 150.56 276.83 183.38 307.42 214.25 48.86 150.56"
                            style="fill: rgb(89, 49, 116); transform-origin: 178.139999px 182.404999px;" id="eltqo2g0svf5o"
                            class="animable"></polygon>
                        <g id="elp8jqbxsnwm">
                            <polygon points="48.86 150.56 276.83 183.38 307.42 214.25 48.86 150.56"
                                style="fill: rgb(255, 255, 255); opacity: 0.6; transform-origin: 178.139999px 182.404999px;"
                                class="animable"></polygon>
                        </g>
                        <polygon points="48.86 150.56 276.83 183.38 266.25 228.02 48.86 150.56"
                            style="fill: rgb(89, 49, 116); transform-origin: 162.844994px 189.290001px;" id="el13z4706dg50a"
                            class="animable"></polygon>
                        <g id="elv1py6c5ryz">
                            <polygon points="48.86 150.56 276.83 183.38 266.25 228.02 48.86 150.56"
                                style="opacity: 0.2; transform-origin: 162.844994px 189.290001px;" class="animable"></polygon>
                        </g>
                        <path
                            d="M261.57,98.88l13.23,62a6.25,6.25,0,0,1-4.8,7.41l-118.08,25.2a6.26,6.26,0,0,1-7.41-4.8l-13.24-62a6.26,6.26,0,0,1,4.81-7.41l118.08-25.2a6.26,6.26,0,0,1,7.41,4.81Z"
                            style="fill: rgb(89, 49, 116); transform-origin: 203.036171px 143.785221px;" id="elt06rjxmw4d"
                            class="animable"></path>
                        <g id="el30zq6zct9tv">
                            <path
                                d="M261.57,98.88l13.23,62a6.25,6.25,0,0,1-4.8,7.41l-118.08,25.2a6.26,6.26,0,0,1-7.41-4.8l-13.24-62a6.26,6.26,0,0,1,4.81-7.41l118.08-25.2a6.26,6.26,0,0,1,7.41,4.81Z"
                                style="opacity: 0.4; transform-origin: 203.036171px 143.785221px;" class="animable"></path>
                        </g>
                        <path
                            d="M258.66,94.82l-39.45,48.43a25.43,25.43,0,0,1-31.15,6.65l-55.79-28.1a6.22,6.22,0,0,1,3.81-2.53l118.08-25.2A6.23,6.23,0,0,1,258.66,94.82Z"
                            style="fill: rgb(89, 49, 116); transform-origin: 195.465012px 123.275736px;" id="el9ufpmljc0ou"
                            class="animable"></path>
                        <polygon points="48.86 150.56 236.89 195.25 266.25 228.02 48.86 150.56"
                            style="fill: rgb(89, 49, 116); transform-origin: 157.555px 189.290001px;" id="elk5d4mdll6e8"
                            class="animable"></polygon>
                        <g id="ell21lc38h2e">
                            <polygon points="48.86 150.56 236.89 195.25 266.25 228.02 48.86 150.56"
                                style="fill: rgb(255, 255, 255); opacity: 0.6; transform-origin: 157.555px 189.290001px;"
                                class="animable"></polygon>
                        </g>
                        <g id="el95qduodcth">
                            <rect x="127.15" y="188.08" width="1" height="24.25"
                                style="fill: rgb(89, 49, 116); transform-origin: 127.65px 200.205px; transform: rotate(-59.64deg);"
                                class="animable"></rect>
                        </g>
                        <g id="el2w0xle2cn1s">
                            <rect x="150.62" y="209.59" width="1" height="8.72"
                                style="fill: rgb(89, 49, 116); transform-origin: 151.12px 213.95px; transform: rotate(-59.64deg);"
                                class="animable"></rect>
                        </g>
                        <polygon points="48.86 150.56 236.89 195.25 200.34 239.3 48.86 150.56"
                            style="fill: rgb(89, 49, 116); transform-origin: 142.875px 194.93px;" id="elui3188mqvyj" class="animable">
                        </polygon>
                        <path d="M300.36,255.26a2,2,0,0,1-.74-.14L269,243a2,2,0,0,1,1.47-3.72L301.1,251.4a2,2,0,0,1-.74,3.86Z"
                            style="fill: rgb(89, 49, 116); transform-origin: 285.055872px 247.202154px;" id="eljvae22216o8"
                            class="animable"></path>
                        <g id="el98oh1dejtk">
                            <path d="M300.36,255.26a2,2,0,0,1-.74-.14L269,243a2,2,0,0,1,1.47-3.72L301.1,251.4a2,2,0,0,1-.74,3.86Z"
                                style="opacity: 0.3; transform-origin: 285.055872px 247.202154px;" class="animable"></path>
                        </g>
                        <path
                            d="M298.55,237.7a1.91,1.91,0,0,1-.73-.15L281.37,231a2,2,0,1,1,1.47-3.72l16.45,6.52a2,2,0,0,1,1.13,2.59A2,2,0,0,1,298.55,237.7Z"
                            style="fill: rgb(89, 49, 116); transform-origin: 290.325441px 232.417581px;" id="el7h3zs82h8jw"
                            class="animable"></path>
                        <g id="el7kfxniwdgdc">
                            <path
                                d="M298.55,237.7a1.91,1.91,0,0,1-.73-.15L281.37,231a2,2,0,1,1,1.47-3.72l16.45,6.52a2,2,0,0,1,1.13,2.59A2,2,0,0,1,298.55,237.7Z"
                                style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 290.325441px 232.417581px;"
                                class="animable"></path>
                        </g>
                        <path d="M264.16,255a2.1,2.1,0,0,1-.73-.14L233.56,243a2,2,0,1,1,1.47-3.72L264.9,251.1a2,2,0,0,1-.74,3.86Z"
                            style="fill: rgb(89, 49, 116); transform-origin: 249.223921px 247.067583px;" id="el3y1ufvvnb92"
                            class="animable"></path>
                        <g id="el3j3xwg1hvxc">
                            <path d="M264.16,255a2.1,2.1,0,0,1-.73-.14L233.56,243a2,2,0,1,1,1.47-3.72L264.9,251.1a2,2,0,0,1-.74,3.86Z"
                                style="opacity: 0.3; transform-origin: 249.223921px 247.067583px;" class="animable"></path>
                        </g>
                    </g>
                    <g id="freepik--Character--inject-7" style="transform-origin: 327.046371px 263.309959px;" class="animable">
                        <path
                            d="M372.3,164.82c2.65,5.17,5.08,10.35,7.39,15.65s4.46,10.67,6.34,16.3l.68,2.13c.12.38.29,1,.39,1.44s.19.94.24,1.36a35.31,35.31,0,0,1,.35,4.87,87.3,87.3,0,0,1-.42,9,144.16,144.16,0,0,1-2.72,17.22l-5.49-.61c-.07-5.63-.12-11.32-.32-16.87-.09-2.76-.25-5.53-.53-8.13a30.52,30.52,0,0,0-.6-3.63c-.05-.28-.13-.49-.19-.72s-.12-.31-.22-.61l-.76-1.89c-2.09-5.06-4.44-10.16-6.84-15.24l-7.26-15.24Z"
                            style="fill: rgb(228, 137, 123); transform-origin: 375.015529px 198.805016px;" id="elah2x4e0ybfp"
                            class="animable"></path>
                        <path d="M379.14,230.42l-2.56,4.79,8.18,2.28s1.56-3.85-.3-6.89Z"
                            style="fill: rgb(228, 137, 123); transform-origin: 380.976172px 233.954994px;" id="elou6nj0kechn"
                            class="animable"></path>
                        <polygon points="373.45 242.11 380.97 242.71 384.76 237.49 376.58 235.21 373.45 242.11"
                            style="fill: rgb(228, 137, 123); transform-origin: 379.105011px 238.960007px;" id="elxng7ipqvkw"
                            class="animable"></polygon>
                        <path d="M337.87,130.26c-.11.59-.52,1-.9.92s-.59-.61-.47-1.19.52-1,.9-.92S338,129.68,337.87,130.26Z"
                            style="fill: rgb(38, 50, 56); transform-origin: 337.186892px 130.125088px;" id="el6vn3uy8ovw"
                            class="animable"></path>
                        <path d="M337.41,131.26a21.34,21.34,0,0,1-3.79,4.41,3.44,3.44,0,0,0,2.67,1.08Z"
                            style="fill: rgb(222, 87, 83); transform-origin: 335.514999px 134.006953px;" id="elzsh3tq7muai"
                            class="animable"></path>
                        <path
                            d="M340.65,127.47a.36.36,0,0,1-.25-.11,2.78,2.78,0,0,0-2.33-.94.35.35,0,0,1-.12-.68,3.4,3.4,0,0,1,3,1.15.35.35,0,0,1,0,.49A.36.36,0,0,1,340.65,127.47Z"
                            style="fill: rgb(38, 50, 56); transform-origin: 339.383286px 126.593753px;" id="el7nsknrd2pb"
                            class="animable"></path>
                        <path
                            d="M354.41,138.43c-1.05,5.38-2.1,15.22,1.66,18.81,0,0-1.47,5.45-11.46,5.45-11,0-5.24-5.45-5.24-5.45,6-1.43,5.83-5.88,4.79-10.05Z"
                            style="fill: rgb(228, 137, 123); transform-origin: 346.966391px 150.55999px;" id="el3hwbhmjyv21"
                            class="animable"></path>
                        <path d="M357.58,160.63c.79-2.63,1.66-5.64-.14-6.59-2-1-12.87-2.1-16.62-.15s-2.68,7.3-2.68,7.3Z"
                            style="fill: rgb(38, 50, 56); transform-origin: 348.249211px 157.001179px;" id="elnpgl3h410fi"
                            class="animable"></path>
                        <path
                            d="M380.64,410a2.35,2.35,0,0,1-1.76-.48.71.71,0,0,1,0-.83.83.83,0,0,1,.55-.41c1.08-.24,3.1,1.14,3.19,1.2a.2.2,0,0,1,.07.18.16.16,0,0,1-.13.13A9.34,9.34,0,0,1,380.64,410Zm-.92-1.4-.21,0a.47.47,0,0,0-.32.24c-.16.28-.06.4,0,.44.31.42,1.81.42,2.91.24A5.78,5.78,0,0,0,379.72,408.62Z"
                            style="fill: rgb(89, 49, 116); transform-origin: 380.718937px 409.13629px;" id="elwoi1po0kts"
                            class="animable"></path>
                        <path
                            d="M382.52,409.82l-.08,0c-.76-.42-2.23-2-2.08-2.84a.57.57,0,0,1,.56-.46,1,1,0,0,1,.74.22c.85.7,1,2.82,1,2.91a.16.16,0,0,1-.08.16A.14.14,0,0,1,382.52,409.82Zm-1.48-3H381c-.23,0-.25.14-.26.18-.09.49.85,1.7,1.6,2.27a4.19,4.19,0,0,0-.86-2.3A.64.64,0,0,0,381,406.84Z"
                            style="fill: rgb(89, 49, 116); transform-origin: 381.505359px 408.167226px;" id="el3kf4f7wnigc"
                            class="animable"></path>
                        <path
                            d="M336.53,410c-.94,0-1.85-.13-2.16-.55a.66.66,0,0,1,0-.76.88.88,0,0,1,.56-.41c1.21-.33,3.79,1.13,3.9,1.19a.18.18,0,0,1,.08.18.17.17,0,0,1-.14.14A12.17,12.17,0,0,1,336.53,410Zm-1.17-1.42a1.15,1.15,0,0,0-.33,0,.51.51,0,0,0-.34.24c-.13.22-.08.32,0,.38.33.46,2.19.49,3.57.3A7.87,7.87,0,0,0,335.36,408.6Z"
                            style="fill: rgb(89, 49, 116); transform-origin: 336.580972px 409.115754px;" id="el976pmfs9zg"
                            class="animable"></path>
                        <path
                            d="M338.75,409.82l-.07,0c-.89-.39-2.64-2-2.51-2.81,0-.2.17-.44.65-.49a1.22,1.22,0,0,1,1,.3c.94.76,1.13,2.74,1.14,2.83a.17.17,0,0,1-.07.16A.17.17,0,0,1,338.75,409.82Zm-1.79-3h-.11c-.31,0-.33.16-.34.2-.08.5,1.12,1.75,2,2.29a4,4,0,0,0-1-2.26A.87.87,0,0,0,337,406.84Z"
                            style="fill: rgb(89, 49, 116); transform-origin: 337.562241px 408.168362px;" id="eld3ha5p8x8tg"
                            class="animable"></path>
                        <polygon points="346.83 409.64 339.48 409.64 338.75 392.63 346.1 392.63 346.83 409.64"
                            style="fill: rgb(228, 137, 123); transform-origin: 342.789993px 401.13501px;" id="el86va34p65yw"
                            class="animable"></polygon>
                        <polygon points="390.94 409.64 383.59 409.64 379.9 392.63 387.24 392.63 390.94 409.64"
                            style="fill: rgb(228, 137, 123); transform-origin: 385.419998px 401.13501px;" id="elxknn480d8u"
                            class="animable"></polygon>
                        <path
                            d="M383.07,408.79h8.25a.65.65,0,0,1,.63.51l1.45,6.53a1.08,1.08,0,0,1-1.08,1.3c-2.88-.05-4.28-.22-7.91-.22-2.23,0-6.83.24-9.92.24s-3.48-3-2.22-3.33c5.66-1.24,7.79-2.95,9.6-4.57A1.78,1.78,0,0,1,383.07,408.79Z"
                            style="fill: rgb(38, 50, 56); transform-origin: 382.510468px 412.969991px;" id="elxdxcg0cm47f"
                            class="animable"></path>
                        <path
                            d="M339.53,408.79h8a.65.65,0,0,1,.63.51l1.45,6.53a1.07,1.07,0,0,1-1.06,1.3c-2.89-.05-7.07-.22-10.71-.22-4.25,0-7.93.24-12.92.24-3,0-3.85-3-2.59-3.33,5.75-1.26,10.44-1.39,15.41-4.45A3.36,3.36,0,0,1,339.53,408.79Z"
                            style="fill: rgb(38, 50, 56); transform-origin: 335.700911px 412.970001px;" id="elmgibxgyczut"
                            class="animable"></path>
                        <path
                            d="M336,170.14a169.18,169.18,0,0,1-18.31-2.43c-3-.58-6.06-1.23-9.1-2.05a73.14,73.14,0,0,1-9.16-3c-.77-.32-1.55-.67-2.32-1a20.47,20.47,0,0,1-2.5-1.46c-1.44-.95-2.68-1.94-3.91-2.92-2.45-2-4.72-4-7-6.05-4.47-4.14-8.71-8.42-12.8-12.92l3.68-4.11,14.51,10.43c2.39,1.72,4.81,3.41,7.19,5,1.18.79,2.39,1.53,3.5,2.16a12.16,12.16,0,0,0,1.44.7c.59.23,1.19.46,1.82.68a108,108,0,0,0,16.53,3.7c5.71.9,11.61,1.64,17.31,2.24Z"
                            style="fill: rgb(228, 137, 123); transform-origin: 303.889999px 152.169998px;" id="el4pi76w4wjga"
                            class="animable"></path>
                        <path
                            d="M369.22,158.9c6.89,1.58,13.85,21.71,13.85,21.71L368.9,190.78s-4-9.38-7.86-17.58C357,164.61,362.18,157.29,369.22,158.9Z"
                            style="fill: rgb(89, 49, 116); transform-origin: 371.346269px 174.728167px;" id="el129pgugm7zf"
                            class="animable"></path>
                        <g id="elewwp2n34yg">
                            <path
                                d="M369.22,158.9c6.89,1.58,13.85,21.71,13.85,21.71L368.9,190.78s-4-9.38-7.86-17.58C357,164.61,362.18,157.29,369.22,158.9Z"
                                style="opacity: 0.4; transform-origin: 371.346269px 174.728167px;" class="animable"></path>
                        </g>
                        <path d="M365.25,169.9l-2.81,6.29c2.91,6.28,5.57,12.5,6.28,14.16C368.62,182.92,367.21,173.7,365.25,169.9Z"
                            style="fill: rgb(89, 49, 116); transform-origin: 365.580002px 180.124992px;" id="elgav3nsbkqa9"
                            class="animable"></path>
                        <g id="eloldu11wyvu">
                            <path d="M365.25,169.9l-2.81,6.29c2.91,6.28,5.57,12.5,6.28,14.16C368.62,182.92,367.21,173.7,365.25,169.9Z"
                                style="opacity: 0.6; transform-origin: 365.580002px 180.124992px;" class="animable"></path>
                        </g>
                        <path
                            d="M328.07,159.28s-7.82,8.83-.44,63.5h39.29c.28-6-3.51-35.46,2.3-63.88a102.56,102.56,0,0,0-13.15-1.66,145.27,145.27,0,0,0-16.7,0A73.5,73.5,0,0,0,328.07,159.28Z"
                            style="fill: rgb(89, 49, 116); transform-origin: 346.84017px 189.889904px;" id="ell43eq9tutla"
                            class="animable"></path>
                        <g id="el7o7sdxgis64">
                            <path
                                d="M328.07,159.28s-7.82,8.83-.44,63.5h39.29c.28-6-3.51-35.46,2.3-63.88a102.56,102.56,0,0,0-13.15-1.66,145.27,145.27,0,0,0-16.7,0A73.5,73.5,0,0,0,328.07,159.28Z"
                                style="opacity: 0.4; transform-origin: 346.84017px 189.889904px;" class="animable"></path>
                        </g>
                        <path d="M325.16,169.9c-.26,2.07-.46,4.52-.59,7.41a41.08,41.08,0,0,0,10.94-5.14Z"
                            style="fill: rgb(89, 49, 116); transform-origin: 330.040009px 173.604996px;" id="el7fmoymgnkm5"
                            class="animable"></path>
                        <g id="ele4d9fnj8rfn">
                            <path d="M325.16,169.9c-.26,2.07-.46,4.52-.59,7.41a41.08,41.08,0,0,0,10.94-5.14Z"
                                style="opacity: 0.6; transform-origin: 330.040009px 173.604996px;" class="animable"></path>
                        </g>
                        <path
                            d="M342,171c-5.26,4.66-25.34,4.19-25.34,4.19L316,155.75a148.17,148.17,0,0,0,20.47,1.48C347.66,157,347.74,165.86,342,171Z"
                            style="fill: rgb(89, 49, 116); transform-origin: 330.880577px 165.476623px;" id="elgnya8ftqhd9"
                            class="animable"></path>
                        <g id="elii44y640e7e">
                            <path
                                d="M342,171c-5.26,4.66-25.34,4.19-25.34,4.19L316,155.75a148.17,148.17,0,0,0,20.47,1.48C347.66,157,347.74,165.86,342,171Z"
                                style="opacity: 0.4; transform-origin: 330.880577px 165.476623px;" class="animable"></path>
                        </g>
                        <path d="M274.77,134.24l-5-5.89-5.39,6s3.32,5.58,8.59,4.7Z"
                            style="fill: rgb(228, 137, 123); transform-origin: 269.574982px 133.747069px;" id="elp3dkqblwh4"
                            class="animable"></path>
                        <polygon points="262.61 121.48 260.67 129.17 264.34 134.31 269.73 128.35 262.61 121.48"
                            style="fill: rgb(228, 137, 123); transform-origin: 265.200012px 127.895px;" id="ellhspn9ophj"
                            class="animable"></polygon>
                        <polygon points="338.75 392.64 339.13 401.4 346.48 401.4 346.1 392.64 338.75 392.64"
                            style="fill: rgb(206, 111, 100); transform-origin: 342.615005px 397.020004px;" id="elm77cl1jtpv9"
                            class="animable"></polygon>
                        <polygon points="387.25 392.64 379.9 392.64 381.8 401.4 389.15 401.4 387.25 392.64"
                            style="fill: rgb(206, 111, 100); transform-origin: 384.524994px 397.020004px;" id="eluf8xfcfmkg"
                            class="animable"></polygon>
                        <path d="M343.17,123c-6.59-3-8.67-16.33,10.29-13,0,0-2.86,3.16,2.94,5.16S357.35,129.51,343.17,123Z"
                            style="fill: rgb(38, 50, 56); transform-origin: 348.636742px 117.173729px;" id="eldailyerz36q"
                            class="animable"></path>
                        <path
                            d="M357.49,133c-1.89,7-2.63,11.26-7,14.19-6.54,4.4-14.67-.65-14.93-8.12-.23-6.71,2.9-17.1,10.45-18.52A9.94,9.94,0,0,1,357.49,133Z"
                            style="fill: rgb(228, 137, 123); transform-origin: 346.705441px 134.596667px;" id="elnx9ygjm866"
                            class="animable"></path>
                        <path
                            d="M353.2,113.5c4.49,2.5,10.57,4,10,7.56-.75,4.35,3.67,1.63,2.64,8.1-.67,4.23-8.47,17.05-14,16.43-8.26-1-7.21-13.56-7.24-20.18C344.58,121.5,344.4,108.57,353.2,113.5Z"
                            style="fill: rgb(38, 50, 56); transform-origin: 355.283961px 129.00318px;" id="elyxg6kn5qi6"
                            class="animable"></path>
                        <path d="M343.61,119.11c-2,.47-4.65,12,3.1,13.06C355.62,133.38,343.61,119.11,343.61,119.11Z"
                            style="fill: rgb(38, 50, 56); transform-origin: 345.750914px 125.676409px;" id="elm8fylfrass"
                            class="animable"></path>
                        <path d="M343.81,123.2c-2.45.22-8-2.73-7.32-7.17C336.49,116,340,121.22,343.81,123.2Z"
                            style="fill: rgb(38, 50, 56); transform-origin: 340.121354px 119.620694px;" id="els5ae7a6n32k"
                            class="animable"></path>
                        <path
                            d="M347.45,133.09a8.21,8.21,0,0,1-3.63,3.85c-2.11,1.1-3.3-.5-2.82-2.62.43-1.9,2.05-4.77,4.22-5S348.32,131.09,347.45,133.09Z"
                            style="fill: rgb(228, 137, 123); transform-origin: 344.333985px 133.299587px;" id="elpy5wji6bzhf"
                            class="animable"></path>
                        <path d="M358.45,125a9.85,9.85,0,0,0,5.78-7.33S367.53,126.84,358.45,125Z"
                            style="fill: rgb(38, 50, 56); transform-origin: 361.632344px 121.457006px;" id="elp6jcjow68tg"
                            class="animable"></path>
                        <path d="M366.79,124.85a5.07,5.07,0,0,0-4.78-.47S365.09,120.39,366.79,124.85Z"
                            style="fill: rgb(38, 50, 56); transform-origin: 364.400009px 123.794062px;" id="elqo1jygku1tn"
                            class="animable"></path>
                        <path
                            d="M341.57,222.78s8.34,56.14,12.91,77.17c5,23,23.9,99.76,23.9,99.76h13.45S379.38,324.84,376.52,302c-3.1-24.75-9.6-79.26-9.6-79.26Z"
                            style="fill: rgb(89, 49, 116); transform-origin: 366.700012px 311.225006px;" id="elo8ow5v2iwjo"
                            class="animable"></path>
                        <g id="elkw6jd2mh2y">
                            <path
                                d="M341.57,222.78s8.34,56.14,12.91,77.17c5,23,23.9,99.76,23.9,99.76h13.45S379.38,324.84,376.52,302c-3.1-24.75-9.6-79.26-9.6-79.26Z"
                                style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 366.700012px 311.225006px;"
                                class="animable"></path>
                        </g>
                        <path d="M376.23,394.75c-.06,0,1.39,5.17,1.39,5.17h15.14l-.28-4.61Z"
                            style="fill: rgb(89, 49, 116); transform-origin: 384.494131px 397.335007px;" id="elay3oighpv6n"
                            class="animable"></path>
                        <g id="elu8zyyhz9uef">
                            <path d="M376.23,394.75c-.06,0,1.39,5.17,1.39,5.17h15.14l-.28-4.61Z"
                                style="opacity: 0.1; transform-origin: 384.494131px 397.335007px;" class="animable"></path>
                        </g>
                        <path
                            d="M346.84,242.78c9.64,1.89,8.62,37.84,7.46,56.33-2.78-13.07-6.85-38.22-9.63-56.07A3.29,3.29,0,0,1,346.84,242.78Z"
                            style="fill: rgb(89, 49, 116); transform-origin: 349.84793px 270.905582px;" id="elt8bviua8s2o"
                            class="animable"></path>
                        <g id="elxpsx1phvdlr">
                            <path
                                d="M346.84,242.78c9.64,1.89,8.62,37.84,7.46,56.33-2.78-13.07-6.85-38.22-9.63-56.07A3.29,3.29,0,0,1,346.84,242.78Z"
                                style="fill: rgb(255, 255, 255); opacity: 0.4; transform-origin: 349.84793px 270.905582px;"
                                class="animable"></path>
                        </g>
                        <path
                            d="M327.63,222.78s-1,54.92-.21,77.33c.83,23.3,9,99.6,9,99.6h12.44s.46-74.94,1-97.82c.61-24.95,3.62-79.11,3.62-79.11Z"
                            style="fill: rgb(89, 49, 116); transform-origin: 340.298085px 311.244987px;" id="elag9yivdj7dp"
                            class="animable"></path>
                        <g id="ela3tyo672pow">
                            <path
                                d="M327.63,222.78s-1,54.92-.21,77.33c.83,23.3,9,99.6,9,99.6h12.44s.46-74.94,1-97.82c.61-24.95,3.62-79.11,3.62-79.11Z"
                                style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 340.298085px 311.244987px;"
                                class="animable"></path>
                        </g>
                        <path
                            d="M339,390.77a.12.12,0,0,1-.12-.11c-2.2-21.12-7.37-72.22-8-90.55-.67-18.71-.06-60.69.14-73.11a.12.12,0,0,1,.12-.12h0a.13.13,0,0,1,.13.12c-.2,12.42-.81,54.4-.14,73.1.65,18.33,5.82,69.41,8,90.53a.14.14,0,0,1-.12.14Z"
                            style="fill: rgb(89, 49, 116); transform-origin: 334.852046px 308.824989px;" id="elbiyi3m138gu"
                            class="animable"></path>
                        <g id="ely6sw1stv47">
                            <path
                                d="M339,390.77a.12.12,0,0,1-.12-.11c-2.2-21.12-7.37-72.22-8-90.55-.67-18.71-.06-60.69.14-73.11a.12.12,0,0,1,.12-.12h0a.13.13,0,0,1,.13.12c-.2,12.42-.81,54.4-.14,73.1.65,18.33,5.82,69.41,8,90.53a.14.14,0,0,1-.12.14Z"
                                style="fill: rgb(255, 255, 255); opacity: 0.4; transform-origin: 334.852046px 308.824989px;"
                                class="animable"></path>
                        </g>
                        <path d="M334,394.75c-.05,0,.71,5.17.71,5.17h15.13l.41-4.61Z"
                            style="fill: rgb(89, 49, 116); transform-origin: 342.123831px 397.335007px;" id="eluojn2dmeq0f"
                            class="animable"></path>
                        <g id="el4nbr02hrtoq">
                            <path d="M334,394.75c-.05,0,.71,5.17.71,5.17h15.13l.41-4.61Z"
                                style="opacity: 0.1; transform-origin: 342.123831px 397.335007px;" class="animable"></path>
                        </g>
                    </g>
                    <defs>
                        <filter id="active" height="200%">
                            <feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius="2"></feMorphology>
                            <feFlood flood-color="#32DFEC" flood-opacity="1" result="PINK"></feFlood>
                            <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE"></feComposite>
                            <feMerge>
                                <feMergeNode in="OUTLINE"></feMergeNode>
                                <feMergeNode in="SourceGraphic"></feMergeNode>
                            </feMerge>
                        </filter>
                        <filter id="hover" height="200%">
                            <feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius="2"></feMorphology>
                            <feFlood flood-color="#ff0000" flood-opacity="0.5" result="PINK"></feFlood>
                            <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE"></feComposite>
                            <feMerge>
                                <feMergeNode in="OUTLINE"></feMergeNode>
                                <feMergeNode in="SourceGraphic"></feMergeNode>
                            </feMerge>
                            <feColorMatrix type="matrix"
                                values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 ">
                            </feColorMatrix>
                        </filter>
                    </defs>
                </svg>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    
}
</script>
<style scoped>
.root {
    position: relative;

    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    height: auto;
    padding: 2rem 0;

    background: linear-gradient(90deg, #593174 0.4%, #EE306F 100%);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    color: white;
}

.container {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.wrapper {
    position: relative;
    z-index: 1;
    flex: 1;
    max-width: 600px;
    min-width: 300px;
    
    text-align: start;
    padding: 2% 5%;
    margin: 2% 0;
}

.wrapper:nth-child(1){
    display: flex;
    flex-direction: column;
    height: 100%;
}

h1{
    font-weight: 900;
}

h2{
    margin-bottom: 4rem;
    font-weight: 500;
}

a {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: clamp(1.25rem, 3vw, 1.75rem);
    margin-bottom: .5rem;
}

a:hover, a:active,
a:hover svg, a:active svg {
    color: #EE306F;
}

@media screen and (min-width: 1028px) {


}

svg {
    max-width: 500px;
}
svg#freepik_stories-mail-sent:not(.animated) .animable {
    opacity: 0;
}

svg#freepik_stories-mail-sent.animated #freepik--background-simple--inject-7 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideLeft;
    animation-delay: 0s;
}

svg#freepik_stories-mail-sent.animated #freepik--Shadow--inject-7 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideRight;
    animation-delay: 0s;
}

svg#freepik_stories-mail-sent.animated #freepik--Mail--inject-7 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideUp;
    animation-delay: 0s;
}

svg#freepik_stories-mail-sent.animated #freepik--Character--inject-7 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) fadeIn;
    animation-delay: 0s;
}

@keyframes slideLeft {
    0% {
        opacity: 0;
        transform: translateX(-30px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideRight {
    0% {
        opacity: 0;
        transform: translateX(30px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideUp {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }

    100% {
        opacity: 1;
        transform: inherit;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
</style>