import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_InfoComponent = _resolveComponent("InfoComponent")!
  const _component_DigitalSignageComponent = _resolveComponent("DigitalSignageComponent")!
  const _component_BusStopComponent = _resolveComponent("BusStopComponent")!
  const _component_PumpStationComponent = _resolveComponent("PumpStationComponent")!
  const _component_TootoBoxComponent = _resolveComponent("TootoBoxComponent")!
  const _component_Info2Component = _resolveComponent("Info2Component")!
  const _component_ContactsComponent = _resolveComponent("ContactsComponent")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeaderComponent),
    _createVNode(_component_InfoComponent),
    _createVNode(_component_DigitalSignageComponent),
    _createVNode(_component_BusStopComponent),
    _createVNode(_component_PumpStationComponent),
    _createVNode(_component_TootoBoxComponent),
    _createVNode(_component_Info2Component),
    _createVNode(_component_ContactsComponent),
    _createVNode(_component_FooterComponent)
  ], 64))
}