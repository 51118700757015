
import HeaderComponent from './components/HeaderComponent.vue'
import InfoComponent from './components/InfoComponent.vue'
import DigitalSignageComponent from './components/DigitalSignageComponent.vue'
import BusStopComponent from './components/BusStopComponent.vue'
import PumpStationComponent from './components/PumpStationComponent.vue'
import TootoBoxComponent from './components/TootoBoxComponent.vue'
import Info2Component from './components/Info2Component.vue'
import ContactsComponent from './components/ContactsComponent.vue'
import FooterComponent from './components/FooterComponent.vue'


export default {
  components: {
    HeaderComponent,
    InfoComponent,
    DigitalSignageComponent,
    BusStopComponent,
    PumpStationComponent,
    TootoBoxComponent,
    Info2Component,
    ContactsComponent,
    FooterComponent
  },

}

