import { createApp } from 'vue'
import App from './App.vue'
import VueScrollTo from "vue-scrollto"

import CookieConsent from 'vue-cookieconsent'
import '../node_modules/vue-cookieconsent/vendor/cookieconsent.css'
import './css/cookieconsent.css'
import Vue3TouchEvents from "vue3-touch-events";

const consentOptions = {
    current_lang: "cs",
    autoclear_cookies: true, // default: false
    page_scripts: true, // default: false

    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0, // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: false,                  // default: false
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    // onFirstAction: function (user_preferences, cookie) {
    //     // callback triggered only once
    // },

    // onAccept: function (cookie) {
    //     // ...
    // },

    // onChange: function (cookie, changed_preferences) {
    //     // ...
    // },

    languages: {
        cs: {
            consent_modal: {
                title: "Tento web využívá cookies",
                description:
                    'Abychom vám zajistili co možná nejsnadnější použití našich webových stránek, používáme soubory cookies. Souhlas s těmito technologiemi nám umožní přizpůsobit obsah vašim potřebám a vyhodnocovat návštěvnost webu. Níže nám můžete udělit svůj souhlas a nastavit zásady užití souborů cookies. Máte možnost si své cookies spravovat sami ->  <button type="button" data-cc="c-settings" class="cc-link">Nastavení cookies</button>',
                primary_btn: {
                    text: "Příjmout vše",
                    role: "accept_all", // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: "Odmítnout vše",
                    role: "accept_necessary", // 'settings' or 'accept_necessary'
                },
            },
            settings_modal: {
                title: "Nastavení",
                save_settings_btn: "Uložit nastavení",
                accept_all_btn: "Příjmout vše",
                reject_all_btn: "Odmítnout vše",
                close_btn_label: "Zavřít",
                cookie_table_headers: [
                    { col1: "Název" },
                    { col2: "Doména" },
                    { col3: "Expirace" },
                    { col4: "Poznámka" },
                ],
                blocks: [
                    {
                        title: "Použití cookies",
                        description:
                            "Pro fungování webových stránek tooto.app využíváme cookies, což jsou malé soubory, které naše stránky pomocí prohlížeče ukládají do vašeho zařízení nebo z něj čtou. Cookies dokáží zaznamenat určité specifické informace z vašich návštěv na webových stránkách. Cookies můžeme podle jejich účelů rozdělit na nezbytné a analytické cookies.",
                    },
                    {
                        title: "Nezbytné",
                        description:
                            "Nezbytné cookies jsou ty, které jsou potřebné pro správné fungování webu - např. zabezpečení webu nebo možnost přihlašování do účtu. Tyto cookies proto nemůžete odmítnout a nepotřebujeme k jejich zpracování váš souhlas.",
                        toggle: {
                            value: "necessary",
                            enabled: true,
                            readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                        },
                    },
                    {
                        title: "Analytické",
                        description:
                            "Analytické cookies nám slouží ke statistikám, přehledům a optimalizaci webu. Díky tomu pak sledujeme návštěvnost našich stránek i to, odkud jste k nám přišli a co vás tu zaujalo. To vše samozřejmě bez identifikace konkrétních uživatelů.",
                        toggle: {
                            value: "analytics", // your cookie category
                            enabled: false,
                            readonly: false,
                        },
                        cookie_table: [
                            // list of all expected cookies
                            {
                                col1: "^_ga", // match all cookies starting with "_ga"
                                col2: "google.com",
                                col3: "2 years",
                                col4: "description ...",
                                is_regex: true,
                            },
                            {
                                col1: "_gid",
                                col2: "google.com",
                                col3: "1 day",
                                col4: "description ...",
                            },
                        ],
                    },
                    // {
                    //     title: "Advertisement and Targeting cookies",
                    //     description:
                    //         "These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you",
                    //     toggle: {
                    //         value: "targeting",
                    //         enabled: false,
                    //         readonly: false,
                    //     },
                    // },
                    {
                        title: "Více informací",
                        description:
                            'Pro více informací prosím kontaktuje info@jvideas.cz <a class="cc-link" href="mailto:info@jvideas.cz">Zeptejte se zde</a>.',
                    },
                ],
            },
        },
    },
}

const app = createApp(App);
app.use(CookieConsent, consentOptions);
app.use(VueScrollTo);
app.use(Vue3TouchEvents);
app.mount('#app');
